<template>
  <Banner
    v-if="alertMessage"
    color-scheme="alert"
    :banner-message="alertMessage"
    has-action-button
    :action-button-label="$t('ALERT_NOTIFICATION.PANEL_LINK_TEXT')"
    @click="openPainel"
  />
</template>

<script>
import Banner from 'dashboard/components/ui/Banner.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'AlertNotification',
  components: { Banner },
  data() {
    return {
      painelUrl: chatwootConfig.alertPaymentUrl,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      getAccount: 'accounts/getAccount',
    }),
    dates() {
      const account = this.getAccount(this.accountId);
      const customAttributes = account?.custom_attributes || {};

      const { cancellation_date, deletion_date } = customAttributes;

      const validCancellationDate = this.isValidDate(cancellation_date);
      const validDeletionDate = this.isValidDate(deletion_date);

      if (!validCancellationDate && !validDeletionDate) {
        return null;
      }

      return {
        cancellation_date: validCancellationDate ? new Date(cancellation_date) : null,
        deletion_date: validDeletionDate ? new Date(deletion_date) : null,
      };
    },
    alertMessage() {
      if (!this.dates) return null;

      const { cancellation_date, deletion_date } = this.dates;
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (cancellation_date && cancellation_date > today) {
        return this.$t('ALERT_NOTIFICATION.BOTH_DATES', {
          cancellation_date: this.formatDate(cancellation_date),
          deletion_date: this.formatDate(deletion_date),
        });
      } else if (deletion_date) {
        return this.$t('ALERT_NOTIFICATION.ONLY_DELETION_DATE', {
          deletion_date: this.formatDate(deletion_date),
        });
      }

      return null;
    },
  },
  methods: {
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString(this.$root.$i18n.locale?.replace('_', '-'), options);
    },
    openPainel() {
      window.open(this.painelUrl, '_blank');
    },
    isValidDate(date) {
      return (
        date &&
        !isNaN(new Date(date).getTime()) &&
        Object.prototype.toString.call(new Date(date)) === '[object Date]'
      );
    },
  },
};
</script>

