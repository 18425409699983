export const LOCAL_STORAGE_KEYS = {
  CONTACTS_LIMIT_REACHED: 'contactsLimitReached',
  CONTACTS_LIMIT_WARNING: 'contactsLimitWarning',
  INBOXES_LIMIT_REACHED: 'inboxesLimitReached',
  INBOXES_LIMIT_WARNING: 'inboxesLimitWarning',
  AGENTS_LIMIT_REACHED: 'agentsLimitReached',
  AGENTS_LIMIT_WARNING: 'agentsLimitWarning',
  DISMISSED_UPDATES: 'dismissedUpdates',
  WIDGET_BUILDER: 'widgetBubble_',
  DRAFT_MESSAGES: 'draftMessages',
  COLOR_SCHEME: 'color_scheme',
  DISMISSED_LABEL_SUGGESTIONS: 'labelSuggestionsDismissed',
  MESSAGE_REPLY_TO: 'messageReplyTo',
};
