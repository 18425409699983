<script>
import Banner from 'dashboard/components/ui/Banner.vue';
import { mapGetters } from 'vuex';
import { hasPassedAccountMaxLimit, isLessThanTenPercentLeft } from 'dashboard/helper/featureHelper';
import { LOCAL_STORAGE_KEYS } from 'dashboard/constants/localStorage';
import { ACCOUNT_LIMITS_TYPES } from 'dashboard/constants/globals';
import { LocalStorage } from 'shared/helpers/localStorage';

export default {
  components: { Banner },
  data() {
    return {
      dismissedBanner: false,
    };
  },
  props: {
    bannerType: {
      type: String,
      required: true,
      validator: (value) => Object.values(ACCOUNT_LIMITS_TYPES).includes(value),
    },
  },
  computed: {
    ...mapGetters({ accountLimits: 'accounts/getAccountLimits' }),
    filteredBanner() {
      if (this.dismissedBanner) return null;
      const limit = this.accountLimits?.[this.bannerType.toLowerCase()];
      if (!limit) return null;

      const { allowed, consumed } = limit;
      if (!allowed || consumed === undefined) return null;

      const isNearLimit = isLessThanTenPercentLeft(limit);
      const hasReachedLimit = hasPassedAccountMaxLimit(limit);

      return (hasReachedLimit || isNearLimit) && !this.isDismissedToday()
        ? { type: this.bannerType, message: this.getLimitMessage(hasReachedLimit, allowed, consumed) }
        : null;
    },
  },
  async mounted() {
    this.checkDismissedStatus();
    await this.fetchLimits();
  },
  methods: {
    async fetchLimits() {
      await this.$store.dispatch('accounts/limits');
    },
    dismissBanner() {
      const today = new Date().toISOString().split('T')[0];
      LocalStorage.set(LOCAL_STORAGE_KEYS[`${this.bannerType}_LIMIT_REACHED`], today);
      this.dismissedBanner = true;
    },
    isDismissedToday() {
      const today = new Date().toISOString().split('T')[0];
      return LocalStorage.get(LOCAL_STORAGE_KEYS[`${this.bannerType}_LIMIT_REACHED`]) === today;
    },
    checkDismissedStatus() {
      this.dismissedBanner = this.isDismissedToday();
    },
    getLimitMessage(hasReachedLimit, allowed, consumed) {
      const key = hasReachedLimit
        ? `GENERAL_SETTINGS.${this.bannerType}_UPGRADE_ALERT`
        : `GENERAL_SETTINGS.${this.bannerType}_UPGRADE_WARNING`;
      return this.$t(key, { allowed, consumed });
    },
  },
};
</script>

<template>
  <Banner
    v-if="filteredBanner"
    :key="filteredBanner.type"
    :banner-message="filteredBanner.message"
    color-scheme="alert"
    has-close-button
    @close="dismissBanner"
  />
</template>

